import React, { useContext } from "react";
import "./menu.scss";
import { Link, useLocation } from "react-router-dom";
import {
  AccountCircleOutlined,
  AlignVerticalBottomOutlined,
  AlignVerticalCenterOutlined,
  SmsFailedOutlined,
} from "@mui/icons-material";
import incubator from "../../assets/categories/incubator.svg";
// import CropFreeOutlined from "@mui/icons-material/CropFreeOutlined";
// import ShoppingBagOutlined from '@mui/icons-material/ShoppingBagOutlined';
import PeopleOutlineOutlined from "@mui/icons-material/PeopleOutlineOutlined";
import ViewCozyOutlined from "@mui/icons-material/ViewCozyOutlined";
import Brightness5Outlined from "@mui/icons-material/Brightness5Outlined";
import RemoveCircleOutlineOutlined from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { Tooltip } from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import {
  ProtectedSectionForAdmin_Log,
  ProtectedSectionForAdmin_Log_Fin,
} from "../../_service/WithPermission";

const Menu = () => {
  const { dispatch } = useContext<any>(AuthContext);
  const handleDisconnect = () => {
    dispatch({ type: "LOGOUT" });
  };

  let location = useLocation();
  let urlRoute = location.pathname.split("/");

  return (
    <div className="menu">
      <div className="primary">
        <Link to="/" className="active">
          <Tooltip title="Dashboard">
            <div className="icon_chart">
              <AlignVerticalBottomOutlined />
            </div>
          </Tooltip>
        </Link>
        <Link to="/" className="active">
          <div className="item">
            <div className="icon"></div>
            <span>Dashboard</span>
          </div>
        </Link>
        <Link
          to="/clients"
          className={
            urlRoute[1] === "clients" ? "menu_active cl" : "menu_item cl"
          }
        >
          <div className="item">
            <div className="icon">
              <PeopleOutlineOutlined />
            </div>
            <span>Clients</span>
          </div>
        </Link>
        <ProtectedSectionForAdmin_Log_Fin>
          <Link
            to="/suppliers"
            className={
              urlRoute[1] === "suppliers" ? "menu_active" : "menu_item"
            }
          >
            <div className="item">
              <AccountCircleOutlined className="icon" />
              <span>Fournisseurs</span>
            </div>
          </Link>
        </ProtectedSectionForAdmin_Log_Fin>
        <Link
          to="/orders/list"
          className={urlRoute[1] === "orders" ? "menu_active" : "menu_item"}
        >
          <div className="item">
            <AlignVerticalCenterOutlined className="icon" />
            <span>Commandes</span>
          </div>
        </Link>
        <ProtectedSectionForAdmin_Log_Fin>
          <Link
            to="/incubation"
            className={
              urlRoute[1] === "incubation" ? "menu_active" : "menu_item"
            }
          >
            <div className="item">
              <img src={incubator} alt="chiken" height={20} />
              <span>Incubation</span>
            </div>
          </Link>
        </ProtectedSectionForAdmin_Log_Fin>
        {/* <Link
          to="/rapports"
          className={urlRoute[1] === "rapports" ? "menu_active" : "menu_item"}
        >
          <div className="item">
            <BarChartRounded className="icon" />
            <span>Rapport</span>
          </div>
        </Link> */}
        {/* <Link to="/purchases" className={urlRoute[1]==="purchases"? "menu_active":"menu_item"}>
          <div className="item">
            <AddShoppingCartOutlined className="icon" />
            <span>Achat</span>
          </div>
        </Link>
        <Link to="/sales" className={urlRoute[1]==="sales"? "menu_active":"menu_item"}>
          <div className="item">
            <ShoppingBagOutlined className="icon" />
            <span>Vente</span>
          </div>
        </Link> */}

        {/* <Link
          to="/reservations"
          className={
            urlRoute[1] === "reservations" ? "menu_active" : "menu_item"
          }
        >
          <div className="item">
            <CropFreeOutlined className="icon" />
            <span>Chambre Froide</span>
          </div>
        </Link> */}
        <ProtectedSectionForAdmin_Log_Fin>
          <Link
            to="/store"
            className={urlRoute[1] === "store" ? "menu_active" : "menu_item"}
          >
            <div className="item">
              <ViewCozyOutlined className="icon" />
              <span>Stock</span>
            </div>
          </Link>
        </ProtectedSectionForAdmin_Log_Fin>
        <ProtectedSectionForAdmin_Log>
          <Link
            to="/messages"
            className={urlRoute[1] === "messages" ? "menu_active" : "menu_item"}
          >
            <div className="item">
              <SmsFailedOutlined className="icon" />
              <span>Notification cliente</span>
            </div>
          </Link>
        </ProtectedSectionForAdmin_Log>
      </div>
      <div className="secondary">
        <Link
          to="/configuration"
          className={
            urlRoute[1] === "configuration" ? "menu_active" : "menu_item"
          }
        >
          <div className="item">
            <Brightness5Outlined className="icon" />
            <ProtectedSectionForAdmin_Log message="Catalogue">
              <span>Configuration</span>
            </ProtectedSectionForAdmin_Log>
          </div>
        </Link>
        <Link to={""} className="menu_item">
          <div className="item" onClick={handleDisconnect}>
            <RemoveCircleOutlineOutlined className="icon" />
            <span>Déconnexion</span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Menu;
